const municipalities: Array<string> = [
  'Acton',
  'Arlington',
  'Ashland',
  'Bedford',
  'Bellingham',
  'Belmont',
  'Beverly',
  'Bolton',
  'Boston',
  'Boxborough',
  'Braintree',
  'Brookline',
  'Burlington',
  'Cambridge',
  'Canton',
  'Carlisle',
  'Chelsea',
  'Cohasset',
  'Concord',
  'Danvers',
  'Dedham',
  'Dover',
  'Duxbury',
  'Essex',
  'Everett',
  'Foxborough',
  'Framingham',
  'Franklin',
  'Gloucester',
  'Hamilton',
  'Hanover',
  'Hingham',
  'Holbrook',
  'Holliston',
  'Hopkinton',
  'Hudson',
  'Hull',
  'Ipswich',
  'Lexington',
  'Lincoln',
  'Littleton',
  'Lynn',
  'Lynnfield',
  'Malden',
  'Manchester',
  'Manchester-by-the-Sea',
  'Marblehead',
  'Marlborough',
  'Marshfield',
  'Maynard',
  'Medfield',
  'Medford',
  'Medway',
  'Melrose',
  'Middleton',
  'Milford',
  'Millis',
  'Milton',
  'Nahant',
  'Natick',
  'Needham',
  'Newton',
  'Norfolk',
  'North Reading',
  'Norwell',
  'Norwood',
  'Peabody',
  'Pembroke',
  'Quincy',
  'Randolph',
  'Reading',
  'Revere',
  'Rockland',
  'Rockport',
  'Salem',
  'Saugus',
  'Scituate',
  'Sharon',
  'Sherborn',
  'Somerville',
  'Southborough',
  'Stoneham',
  'Stoughton',
  'Stow',
  'Sudbury',
  'Swampscott',
  'Topsfield',
  'Wakefield',
  'Walpole',
  'Waltham',
  'Watertown',
  'Wayland',
  'Wellesley',
  'Wenham',
  'Weston',
  'Westwood',
  'Weymouth',
  'Wilmington',
  'Winchester',
  'Winthrop',
  'Woburn',
  'Wrentham'
];

export default municipalities;